//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataObjectParser from 'dataobject-parser'
import merge from 'deepmerge'
export default {
  data() {
    return {
      intro: {},
    }
  },
  computed: {
    orderedProjects() {
      return Object.values(this.projects).sort((a, b) => b.order - a.order)
    },
    orderedConsulting() {
      return Object.values(this.consulting).sort((a, b) => b.order - a.order)
    }
  },
  async asyncData({ $content }) {
    const content = await $content('index', { deep: true })
      .fetch()
      .then((res) =>
        res.reduce((acc, file) => {
          return merge(
            acc,
            new DataObjectParser().set(file.path.replace('/', '.'), file).data()
          )
        }, {})
      )
      .catch(console.error)
    return { ...content.index }
  },
  mounted() {
    console.log(`


██╗  ██╗██╗██████╗ ███████╗    ███╗   ███╗███████╗       ███╗   ███╗ █████╗ ██╗   ██╗██████╗ ███████╗██████╗
██║  ██║██║██╔══██╗██╔════╝    ████╗ ████║██╔════╝       ████╗ ████║██╔══██╗╚██╗ ██╔╝██╔══██╗██╔════╝╚════██╗
███████║██║██████╔╝█████╗      ██╔████╔██║█████╗         ██╔████╔██║███████║ ╚████╔╝ ██████╔╝█████╗    ▄███╔╝
██╔══██║██║██╔══██╗██╔══╝      ██║╚██╔╝██║██╔══╝         ██║╚██╔╝██║██╔══██║  ╚██╔╝  ██╔══██╗██╔══╝    ▀▀══╝
██║  ██║██║██║  ██║███████╗    ██║ ╚═╝ ██║███████╗▄█╗    ██║ ╚═╝ ██║██║  ██║   ██║   ██████╔╝███████╗  ██╗
╚═╝  ╚═╝╚═╝╚═╝  ╚═╝╚══════╝    ╚═╝     ╚═╝╚══════╝╚═╝    ╚═╝     ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═════╝ ╚══════╝  ╚═╝



`)
  },
}
